
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/weather-pouring': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 12a1 1 0 0 1 .707 1.225l-1.294 4.83a1 1 0 0 1-1.932-.518l1.294-4.83A1 1 0 0 1 9 12zm4 0a1 1 0 0 1 .707 1.225l-2.07 7.727a1 1 0 0 1-1.932-.518l2.07-7.727A1 1 0 0 1 13 12zm4 0a1 1 0 0 1 .707 1.225l-1.294 4.83a1 1 0 0 1-1.932-.518l1.294-4.83A1 1 0 0 1 17 12zm0-2V9a5 5 0 0 0-9.935-.806 3 3 0 0 0-2.567 5.403l.004-.006a1 1 0 0 1-.998 1.733l-.004.007A5 5 0 0 1 5.999 6a6.502 6.502 0 0 1 12.484 2.032L19 8a4 4 0 0 1 2 7.465l-.004-.007a1 1 0 0 1-.998-1.733l.004.006A2 2 0 0 0 19 10h-2z"/>'
  }
})
