import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import * as getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  getters,
  mutations: {
    setLang(state, lang) {
      state.lang = lang;
    }
  }
});

if (module.hot) {
  module.hot.accept(["./state", "./getters"], () => {
    store.hotUpdate({
      state: require("./state"),
      getters: require("./getters")
    });
  });
}

export default store;
