<template>
  <div id="app" class="d-flex flex-column">
    <foz-header></foz-header>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <div id="widgets" class="d-none d-sm-block" :style="{ display: isHome() }">
      <foz-social></foz-social>
      <foz-weather></foz-weather>
      <foz-webcam></foz-webcam>
    </div>
    <foz-footer></foz-footer>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import FozHeader from "@/components/FozHeader";
import FozFooter from "@/components/FozFooter";
import FozSocial from "@/components/FozSocial";
import FozWeather from "@/components/FozWeather";
import FozWebcam from "@/components/FozWebcam";

export default {
  components: { FozHeader, FozFooter, FozSocial, FozWeather, FozWebcam },
  data() {
    return {
      base_url: "https://www.lafermedoz.fr"
    };
  },
  metaInfo() {
    return {
      title: Vue.i18n.translate("pages." + this.route.name + ".title", ""),
      titleTemplate: titleChunk => {
        return titleChunk
          ? `❄ ${titleChunk} ❄ La Ferme d’Oz ❄`
          : "❄ La Ferme d’Oz ❄";
      },
      meta: [
        {
          property: "description",
          content: Vue.i18n.translate(
            "pages." + this.route.name + ".description",
            ""
          )
        },
        { property: "og:type", content: "website" },
        { property: "og:image", content: "/og-image.jpg" },
        {
          property: "og:locale",
          content: this.$i18n.locale() + "_" + this.$i18n.locale().toUpperCase()
        },
        {
          property: "og:title",
          content: Vue.i18n.translate("pages." + this.route.name + ".title", "")
        },
        {
          property: "og:description",
          content: Vue.i18n.translate(
            "pages." + this.route.name + ".description",
            ""
          )
        },
        { property: "og:url", content: this.base_url + this.route.fullPath },
        { name: "twitter:card", content: "summary_large_image" },
        { property: "twitter:domain", content: this.base_url },
        {
          property: "twitter:url",
          content: this.base_url + this.route.fullPath
        },
        {
          property: "twitter:title",
          content: Vue.i18n.translate("pages." + this.route.name + ".title", "")
        },
        {
          property: "twitter:description",
          content: Vue.i18n.translate(
            "pages." + this.route.name + ".description",
            ""
          )
        },
        { property: "twitter:image", content: "/og-image.jpg" }
      ],
      link: [
        {
          rel: "alternate",
          hreflang: this.$i18n.locale() === "en" ? "fr" : "en",
          href: `${this.base_url}/${this.$i18n.locale() === "en" ? "fr" : "en"}`
        }
      ]
    };
  },
  methods: {
    isHome() {
      return true;
      // return this.route.name === "home" ? "block !important" : "inherit";
    }
  },
  computed: {
    ...mapState(["route"])
  },
  created() {
    this.$i18n.set(this.route.params.lang);
  },
  watch: {
    $route(to) {
      let newLang = to.params.lang;
      if (newLang !== this.$i18n.locale()) {
        this.$i18n.set(newLang);
      }
    }
  }
};
</script>

<style lang="postcss">
:root {
  --white: #ffffff;
  --gray: #2f2f2f;
  --orange: #e44132;
}

body {
  overflow-x: hidden;
  line-height: 1.1rem;
  font-family: "Grota Sans Regular";
}

a {
  outline: 0;
}

@font-face {
  font-family: "Grota Sans Regular";
  src: url("./assets/fonts/grota_sans_regular.woff2") format("woff2"),
    url("./assets/fonts/grota_sans_regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Grota Sans Medium";
  src: url("./assets/fonts/grota_sans_medium.woff2") format("woff2"),
    url("./assets/fonts/grota_sans_medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Grota Sans Semibold";
  src: url("./assets/fonts/grota_sans_semi_bold.woff2") format("woff2"),
    url("./assets/fonts/grota_sans_semi_bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.text-primary {
  color: var(--orange) !important;
}

.text-gray {
  color: var(--gray);
  &:hover {
    color: color-mod(var(--gray) shade(15%));
  }
}

.bg-dark {
  background-color: color-mod(var(--gray) alpha(90%)) !important;
}

.bg-primary {
  background-color: var(--orange) !important;
}

a {
  & .bg-primary,
  & .bg-white {
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &:hover .bg-primary {
    background-color: color-mod(var(--orange) shade(7.5%)) !important;
  }
  &:hover .bg-white {
    background-color: color-mod(var(--white) shade(7.5%)) !important;
  }
}

.bg-light {
  background-color: color-mod(var(--white) alpha(90%)) !important;
}

.btn-primary {
  color: var(--white);
  background-color: var(--orange);
  border-color: var(--orange);
  &:hover {
    background-color: color-mod(var(--orange) shade(7.5%));
    border-color: color-mod(var(--orange) shade(10%));
  }
}

.btn {
  font-family: "Grota Sans Semibold";
}

.btn-link {
  font-family: "Grota Sans Regular";
}

.btn-white {
  color: var(--white);
  text-decoration: underline;
  &:hover {
    color: color-mod(var(--white) shade(10%));
  }
}

#app {
  color: var(--white);
  background-color: color-mod(var(--gray) shade(15%));
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  padding-top: 5rem;
}

.foz-flex1 {
  flex: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Slide Left */
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.25s;
}
.slide-left-enter,
.slide-right-leave-to {
  transform: translateX(100%);
}
.slide-left-leave-to,
.slide-right-enter {
  transform: translateX(-100%);
}

.svg-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: inherit;
  vertical-align: top !important;
  fill: none;
  stroke: currentColor;
}

.svg-fill {
  fill: currentColor;
  stroke: none;
}

.pulse {
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0%,
  100% {
    background-color: var(--orange);
  }
  50% {
    background-color: color-mod(var(--orange) shade(40%));
  }
}
</style>

<style lang="postcss" scoped>
#widgets {
  position: fixed;
  top: 20%;
  right: 0;
}
</style>
