<template>
  <section class="d-flex flex-column justify-content-center foz-flex1">
    <b-container>
      <transition :name="transitionName" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </b-container>
  </section>
</template>

<script>
import jpg from "@/assets/images/background-drinks.jpg";
import svg from "@/assets/svg/illustration-boissons.svg";

export default {
  data() {
    return {
      transitionName: "fade"
    };
  },
  watch: {
    $route(to) {
      if (to.params.transitionName !== undefined) {
        this.transitionName = to.params.transitionName;
      } else {
        this.transitionName = "fade";
      }
    }
  },
  mounted: function() {
    var cstyle = document.getElementById("app").style;
    cstyle.backgroundImage = 'url("' + svg + '"), url("' + jpg + '")';
    cstyle.backgroundSize = "35vw, cover";
    cstyle.backgroundPosition = "left -5vw bottom 5vh, center center";
    cstyle.backgroundAttachment = "fixed";
  }
};
</script>
