
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/thermometer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 17a5 5 0 1 1-8-4V5a3 3 0 1 1 6 0v8c1.214.912 2 2.364 2 4zm-6-9v6.17a3.001 3.001 0 1 0 2 0V8h-2z"/>'
  }
})
