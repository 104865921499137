
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/phone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.623 10.793a15.068 15.068 0 0 0 6.589 6.585l2.2-2.203c.275-.275.67-.356 1.016-.245 1.124.367 2.325.568 3.572.568a1 1 0 0 1 1 1v3.5a1 1 0 0 1-1 1c-9.39 0-17.001-7.611-17.001-17a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.247.2 2.448.568 3.572a1 1 0 0 1-.244 1.015l-2.2 2.208z"/>'
  }
})
