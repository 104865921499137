import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import Vue from "vue";
import * as VueSVGIcon from "vue-svgicon";
import VueAnalytics from "vue-ua";
import vuexI18n from "vuex-i18n";
import { sync } from "vuex-router-sync";
import App from "./App.vue";
import translationsEn from "./i18n/en.json";
import translationsFr from "./i18n/fr.json";
import router from "./router";
import store from "./store";

sync(store, router);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueSVGIcon, {
  tagName: "icon",
  defaultWidth: "1.25rem",
  defaultHeight: "1.25rem"
});
Vue.use(vuexI18n.plugin, store);
Vue.use(VueAnalytics, {
  appName: "La Ferme d’Oz",
  appVersion: "0.1",
  trackingId: "UA-16530678-7"
});

Vue.i18n.add("fr", translationsFr);
Vue.i18n.add("en", translationsEn);
Vue.i18n.set("fr");
Vue.i18n.fallback("fr");

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
