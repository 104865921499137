
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/chevron-left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.414 16.584l-4.586-4.586 4.586-4.586-1.415-1.414-6 6 6 6 1.415-1.414z"/>'
  }
})
