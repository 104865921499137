
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drinks/wines': {
    width: 16,
    height: 16,
    viewBox: '0 0 141.7 141.7',
    data: '<g _fill="#2f2f2f"><path pid="0" d="M98.5 60.7c0-6.5-2.2-20.8-5.2-33.9-.5-2-2.2-3.4-4.3-3.4H51.3c-2.1 0-3.8 1.4-4.3 3.4-3 13.1-5.2 27.4-5.2 33.9 0 15.2 12 27.6 26.9 28.3v19.6c0 4.2-3.4 7.6-7.6 7.6h-6c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4H85c.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4h-6c-4.2 0-7.6-3.4-7.6-7.6V89c15.2-.8 27.1-13.2 27.1-28.3zm-53.8 0c0-5.4 1.8-18.6 5.1-33.3.2-.7.8-1.2 1.6-1.2h37.8c.7 0 1.4.5 1.6 1.2C94 42 95.7 55.2 95.7 60.7c0 14.1-11.5 25.5-25.5 25.5-14.1 0-25.5-11.5-25.5-25.5zm27.4 55.4h-3.8c.7-.7 1.4-1.5 1.9-2.4.5.9 1.2 1.7 1.9 2.4zm0 0"/><path pid="1" d="M83 79c3.7-2.6 6.5-6.2 8.1-10.3.2-.4.1-.9-.2-1.3-.3-.4-.7-.6-1.2-.6h-39c-.5 0-.9.2-1.2.6-.3.4-.3.9-.2 1.3 1.6 4.2 4.4 7.8 8.1 10.3 3.8 2.6 8.2 4 12.8 4 4.6 0 9-1.4 12.8-4zm-30.2-9.5h34.8c-1.5 2.8-3.6 5.3-6.2 7.1-3.3 2.3-7.2 3.5-11.2 3.5-4 0-7.9-1.2-11.2-3.5-2.6-1.8-4.7-4.2-6.2-7.1zm0 0M50.6 60.7c0-2.8.5-7.9 1.5-13.9.1-.8-.4-1.5-1.2-1.6-.8-.1-1.5.4-1.6 1.2-1 6.2-1.5 11.4-1.5 14.4 0 .8.6 1.4 1.4 1.4.8-.1 1.4-.8 1.4-1.5zm0 0M51.6 41.1h.3c.7 0 1.3-.5 1.4-1.1l.6-3c.1-.8-.3-1.5-1.1-1.6-.8-.1-1.5.3-1.6 1.1l-.6 3c-.2.7.3 1.4 1 1.6zm0 0"/></g>'
  }
})
