import { render, staticRenderFns } from "./FozMenuCard.vue?vue&type=template&id=011bc9de&"
import script from "./FozMenuCard.vue?vue&type=script&lang=js&"
export * from "./FozMenuCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "FozMenuCard.vue"
export default component.exports