<template>
  <b-row align-v="center" align-h="center" v-if="list != undefined" class="h-100">
    <b-col cols="1" class="pl-0 d-none d-sm-block">
      <b-btn class="p-0" :to="{ name: baseUrl + '-detail', params: { slug: prevSlug, transitionName: 'slide-right' } }">
        <icon name="md/chevron-left" width="2rem" height="auto" />
      </b-btn>
    </b-col>
    <b-col col cols="11" sm="10" class="bg-light text-gray px-md-5 pb-3">
      <b-link :to="{ name: baseUrl + '-list',  hash: list.slug }" class="close position-absolute" style="padding: 15px; top: 0; right: 0;">
        <span aria-hidden="true">×</span>
      </b-link>
      <foz-menu-card v-for="card in list.cards" :key="card.id" :card="card"></foz-menu-card>
    </b-col>
    <b-col cols="1" class="pr-0 text-right d-none d-sm-block">
      <b-btn class="p-0" :to="{ name: baseUrl + '-detail', params: { slug: nextSlug, transitionName: 'slide-left' } }">
        <icon name="md/chevron-right" width="2rem" height="auto" />
      </b-btn>
    </b-col>
  </b-row>
</template>

<script>
import FozMenuCard from "@/components/FozMenuCard";

import "@/icons/md/chevron-left";
import "@/icons/md/chevron-right";

export default {
  components: { FozMenuCard },
  props: ["list", "baseUrl", "nextSlug", "prevSlug"]
};
</script>
