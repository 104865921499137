import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";

import FozHome from "@/components/FozHome";
import FozMenu from "@/components/FozMenu";
import FozMenuDetail from "@/components/FozMenuDetail";
import FozMenuList from "@/components/FozMenuList";
import FozDrinks from "@/components/FozDrinks";
import FozDrinksDetail from "@/components/FozDrinksDetail";
import FozDrinksList from "@/components/FozDrinksList";
import FozPhotos from "@/components/FozPhotos";
import FozContact from "@/components/FozContact";
import FozNotFound from "@/components/FozNotFound";

Vue.use(Router);
Vue.use(Meta);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "",
      redirect: "/fr"
    },
    {
      path: "/:lang",
      name: "home",
      component: FozHome
    },
    {
      path: "/:lang/cartes-et-menus",
      component: FozMenu,
      children: [
        {
          path: "/:lang/cartes-et-menus/:slug",
          name: "menu-detail",
          component: FozMenuDetail
        },
        {
          path: "",
          name: "menu-list",
          component: FozMenuList
        }
      ]
    },
    {
      path: "/:lang/boissons",
      component: FozDrinks,
      children: [
        {
          path: "/:lang/boissons/:slug",
          name: "drinks-detail",
          component: FozDrinksDetail
        },
        {
          path: "",
          name: "drinks-list",
          component: FozDrinksList
        }
      ]
    },
    {
      path: "/:lang/photos",
      name: "photos",
      component: FozPhotos
    },
    {
      path: "/:lang/contact",
      name: "contact",
      component: FozContact
    },
    {
      path: "*",
      name: "notfound",
      component: FozNotFound
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 1000 }
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (!Vue.i18n.localeExists(to.params.lang)) {
    router.push({
      name: to.name,
      params: { lang: from.params.lang ? from.params.lang : "fr" }
    });
  }
  next();
});

router.afterEach(to => {
  Vue.analytics.trackView(to.meta.analytics || to.path, true);
});

export default router;
