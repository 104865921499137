
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/weather-fog': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 15h10a1 1 0 1 1 0 2H3a1 1 0 1 1 0-2zm13 0h5a1 1 0 1 1 0 2h-5a1 1 0 1 1 0-2zM1 12a5 5 0 0 1 4.998-5 6.502 6.502 0 0 1 12.485 2.033L19 9c2.193 0 3.974 1.764 4 4h-2a2 2 0 0 0-2-2h-2v-1a5 5 0 0 0-9.935-.806A3 3 0 0 0 3.17 13H1.1L1 12zm2 7h2a1 1 0 1 1 0 2H3a1 1 0 1 1 0-2zm5 0h13a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2z"/>'
  }
})
