import { render, staticRenderFns } from "./FozWebcam.vue?vue&type=template&id=cebd6e80&"
import script from "./FozWebcam.vue?vue&type=script&lang=js&"
export * from "./FozWebcam.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "FozWebcam.vue"
export default component.exports