
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menus/pension': {
    width: 16,
    height: 16,
    viewBox: '0 0 141.7 141.7',
    data: '<path pid="0" d="M116.6 48.4c-.8-.1-1.6.5-1.7 1.3l-4.3 28.8h-10.4l-.2-5h.5c.5 0 .8-.2 1.1-.5.3-.3.5-.7.5-1.1l-.5-12c0-.8-.7-1.4-1.5-1.4H87.4v-5.2c1.7-.6 3-2.3 3-4.3v-5.1c0-.8-.6-1.5-1.5-1.5h-6c-.8 0-1.5.6-1.5 1.5v5c0 2 1.3 3.6 3 4.3v5.2h-8V43.9c0-2.5-1.7-4.6-4-5.3v-8.7c0-.8-.6-1.5-1.5-1.5-.8 0-1.5.6-1.5 1.5v8.7c-2.4.6-4 2.8-4 5.3v14.5h-8v-5.2c1.7-.6 3-2.3 3-4.3v-5c0-.8-.6-1.5-1.5-1.5h-6c-.8 0-1.5.6-1.5 1.5v5c0 2 1.3 3.6 3 4.3v5.2H41.9c-.8 0-1.4.6-1.5 1.4l-.5 12c0 .5.1.8.5 1.1.3.3.6.5 1.1.5h.5l-.2 5H31.1l-4.3-28.8c-.1-.8-.9-1.4-1.7-1.3-.8.1-1.4.9-1.3 1.7l4.5 29.9v31.9c0 .8.6 1.5 1.5 1.5.8 0 1.5-.6 1.5-1.5V87.4h9.8l-.8 18.4c0 .5.1.8.5 1.1.3.3.6.5 1.1.5 1.9 0 3.3-.3 4.5-.6v5.2c0 .8.6 1.5 1.5 1.5.8 0 1.5-.6 1.5-1.5v-6.4c.2-.1.4-.2.5-.3 1.4-.8 2.8-1.5 5.1-1.7v8.4c0 .8.6 1.5 1.5 1.5.8 0 1.5-.6 1.5-1.5v-8.4c2.3.2 3.5.9 5.1 1.7 1.9 1 4 2.2 8 2.2s6.1-1.2 8-2.2c1.4-.8 2.8-1.5 5.1-1.7v8.4c0 .8.6 1.5 1.5 1.5.8 0 1.5-.6 1.5-1.5v-8.4c2.3.2 3.5.9 5.1 1.7.2.1.3.2.5.3v6.4c0 .8.6 1.5 1.5 1.5.8 0 1.5-.6 1.5-1.5v-5.2c1.2.4 2.6.6 4.5.6.5 0 .8-.2 1.1-.5.3-.3.5-.7.5-1.1l-.9-18.4h9.8v24.5c0 .8.6 1.5 1.5 1.5s1.5-.6 1.5-1.5v-32l4.5-29.9c-.3-.7-.9-1.5-1.7-1.6zm-75.3 36h-9.9v-3h10l-.1 3zm43.1-39.1h3v3.5c0 .8-.6 1.5-1.5 1.5-.8 0-1.5-.6-1.5-1.5v-3.5zm-16-1.4c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v14.5h-5V43.9zm-14 1.4h3v3.5c0 .8-.6 1.5-1.5 1.5-.8 0-1.5-.6-1.5-1.5v-3.5zm38 28H97l1.4 30.9c-2.3-.2-3.5-.9-5-1.7-1.9-1-4-2.2-8-2.2s-6.1 1.2-8 2.2c-1.8 1-3.3 1.8-6.5 1.8s-4.8-.8-6.5-1.8c-1.9-1-4-2.2-8-2.2s-6.1 1.2-8 2.2c-.5.3-.8.5-1.3.6-1 .5-2.2.9-3.7 1.1l1.2-30.9h41.6c.8 0 1.5-.6 1.5-1.5 0-.8-.6-1.5-1.5-1.5H42.9l.4-9h55.2l.4 9h-6.4c-.8.1-1.4.7-1.4 1.6-.2.8.4 1.4 1.3 1.4zm18 11.1h-9.9l-.1-3h10v3z" _fill="#2f2f2f"/>'
  }
})
