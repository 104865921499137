<template>
  <div>
    <h3 class="text-primary mt-4" v-if="card.title">{{ $t(card.title).toUpperCase() }}
      <span v-if="card.price" class="h4 float-right mr-3 text-gray">{{ card.price }}</span>
    </h3>
    <p>
      <small v-if="card.comments">{{ $t(card.comments) }}</small>
    </p>

    <table class="table-sm table-striped w-100" v-if="card.type === 'table'">
      <thead v-if="card.head">
        <tr>
          <td class="border-top-0">{{ $t(card.head.col1) }}</td>
          <td class="border-top-0 text-right" style="width: 4rem">{{ $t(card.head.col2) }}</td>
          <td class="border-top-0 text-right" style="width: 4rem" v-if="card.head.col3">{{ $t(card.head.col3) }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in card.rows" :key="row.id">
          <td class="border-top-0">{{ $t(row.col1) }}<br/>
            <small class="d-none d-md-inline">{{ $t(row.desc) }}</small>
          </td>
          <td class="border-top-0 text-right">{{ row.col2 }}</td>
          <td class="border-top-0 text-right" v-if="row.col3">{{ row.col3 }}</td>
        </tr>
      </tbody>
    </table>

    <div v-if="card.type ==='text'">
      <p v-for="t in card.text" :key="t.id">{{ $t(t) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card"]
};
</script>
