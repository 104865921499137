
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/weather-sunny': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 7a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-7l2.394 3.42A6.987 6.987 0 0 0 12 5c-.84 0-1.647.148-2.394.42L12 2zM3.345 7.009l4.159-.363a6.987 6.987 0 0 0-1.561 1.863c-.42.728-.695 1.5-.833 2.283L3.345 7.01zm.01 10l1.765-3.783c.138.782.413 1.555.833 2.283s.952 1.352 1.561 1.863l-4.159-.363zM20.646 7.004l-1.765 3.783a6.987 6.987 0 0 0-.833-2.283 6.985 6.985 0 0 0-1.56-1.863l4.158.363zm-.01 9.99l-4.16.363a6.989 6.989 0 0 0 1.562-1.864c.42-.728.695-1.5.833-2.283l1.765 3.783zm-8.657 4.985l-2.394-3.42c.747.272 1.553.42 2.394.42.841 0 1.647-.148 2.394-.42l-2.394 3.42z"/>'
  }
})
