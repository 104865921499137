
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/weather-partlycloudy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.741 5.472a5.502 5.502 0 0 1 3.18 5.986A5.987 5.987 0 0 1 18 16l-.002.172A3 3 0 1 1 19 22H6a4 4 0 0 1 0-8l.272.011a5.5 5.5 0 0 1 6.47-8.54zm-.813 1.827a3.5 3.5 0 0 0-4.517 4.835A5.987 5.987 0 0 1 12 10c.702 0 1.376.12 2.001.342a3.5 3.5 0 0 0-2.073-3.043zm1.627-3.654a7.522 7.522 0 0 0-1.671-.522l2.484-1.305.907 2.889a7.507 7.507 0 0 0-1.72-1.062zm-7.466.795a7.52 7.52 0 0 0-1.288 1.186l.112-2.804 2.956.66c-.62.231-1.219.55-1.78.958zm11.887 5.273a7.522 7.522 0 0 0-.383-1.709l2.372 1.5-2.049 2.23c.11-.653.133-1.331.06-2.021zm-14.931 1.59a7.52 7.52 0 0 0 .383 1.708l-2.372-1.499 2.048-2.23a7.507 7.507 0 0 0-.06 2.02zM19 18h-3v-2a4 4 0 0 0-8 0H6a2 2 0 1 0 0 4h13a1 1 0 1 0 0-2z"/>'
  }
})
