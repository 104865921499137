
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/chevron-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.585 16.584l4.586-4.586-4.586-4.586L10 5.998l6 6-6 6-1.414-1.414z"/>'
  }
})
