
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/map-marker': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 11.498a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0-9.5a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7z"/>'
  }
})
