import { render, staticRenderFns } from "./FozDrinksList.vue?vue&type=template&id=022a25b5&scoped=true&"
import script from "./FozDrinksList.vue?vue&type=script&lang=js&"
export * from "./FozDrinksList.vue?vue&type=script&lang=js&"
import style0 from "./FozDrinksList.vue?vue&type=style&index=0&id=022a25b5&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022a25b5",
  null
  
)

component.options.__file = "FozDrinksList.vue"
export default component.exports