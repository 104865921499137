
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/email': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 7.998l-8 5-8-5v-2l8 5 8-5m0-2H4a1.99 1.99 0 0 0-1.99 2l-.01 12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2z"/>'
  }
})
