
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drinks/beers': {
    width: 16,
    height: 16,
    viewBox: '0 0 141.7 141.7',
    data: '<g _fill="#2f2f2f"><path pid="0" d="M99 49.1h-4.9l.1-3.1c3.6-.8 6.3-4 6.3-7.8 0-4.4-3.6-7.9-7.9-7.9h-.8c-2-3.3-5.4-5.3-9.3-5.3-3.1 0-6 1.3-8 3.6-1.5-1-3.3-1.5-5.2-1.5-2 0-4 .6-5.6 1.8-2-2.4-5-3.9-8.2-3.9-3.8 0-7.3 2-9.2 5.3-4.3.1-7.8 3.6-7.8 7.9 0 3.7 2.6 6.9 6.1 7.7l2 58.4-1.8 2.9c-.2.3-.3.6-.3 1v6.8c0 1 .8 1.8 1.8 1.8h46c1 0 1.8-.8 1.8-1.8v-6.8c0-.3-.1-.6-.2-.9l-1.6-2.9.1-1.9H99c6.8 0 12.4-5.6 12.4-12.4V61.4c0-6.8-5.6-12.3-12.4-12.3zm-8.4 64H48.3v-4.4l1.3-2.1h39.9l1.1 2v4.5zm-42.2-67h42.2l-1.8 56.8H50.3l-1.9-56.8zm44-3.6H46.6c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3c.2 0 .5 0 .7.1.8.1 1.6-.3 2-1.1 1.1-2.6 3.6-4.2 6.4-4.2 2.6 0 5 1.5 6.2 3.8.3.6.8.9 1.4 1 .6.1 1.2-.2 1.6-.6 1.1-1.3 2.8-2.1 4.5-2.1 1.6 0 3.1.6 4.2 1.8.4.4 1 .6 1.5.5.6-.1 1.1-.4 1.4-.9 1.2-2.2 3.5-3.5 6-3.5 2.9 0 5.4 1.7 6.4 4.4.4.9 1.3 1.4 2.2 1.1.4-.1.8-.2 1.3-.2 2.4 0 4.3 1.9 4.3 4.3.1 2.2-1.8 4.2-4.3 4.2zm1.4 17.7H99c.7 0 1.2.5 1.2 1.2V90c0 .7-.5 1.2-1.2 1.2h-6.2l1-31zM99 98.8h-6.5l.1-3.9H99c2.7 0 4.8-2.2 4.8-4.8V61.4c0-2.7-2.2-4.8-4.8-4.8h-5.1l.1-3.9h5c4.8 0 8.7 3.9 8.7 8.7V90c0 4.9-3.9 8.8-8.7 8.8zm0 0"/><path pid="1" d="M69.3 52.6c-1 0-1.8.8-1.8 1.8v40.9c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V54.5c0-1-.8-1.9-1.8-1.9zm0 0M58 52.6c-1 0-1.8.8-1.8 1.8v40.9c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V54.5c0-1-.8-1.9-1.8-1.9zm0 0M80.7 52.6c-1 0-1.8.8-1.8 1.8v40.9c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V54.5c.1-1-.7-1.9-1.8-1.9zm0 0"/></g>'
  }
})
