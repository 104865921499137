<template>
  <section class="foz-flex1">
    <b-container class="h-100 text-gray">
      <b-row class="h-100">
        <b-col md=6>
          <div class="bg-light h-100 p-4">
            <h3 class="text-primary">{{ $t('contact.send-your-message') }}</h3>
            <b-form @submit="onSubmit">

              <b-form-group label="Dont fill this out" label-for="secret_field" style="display: none;">
                  <b-form-input id="secret_field" type="text" v-model="form.secret_field"></b-form-input>
                </b-form-group>
            
              <b-form-group :label="$t('contact.fullname')" label-for="fullname">
                <b-form-input id="fullname" type="text" v-model="form.fullname" required></b-form-input>
              </b-form-group>
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" type="email" v-model="form.email" required></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('contact.phone')" label-for="phone">
                <b-form-input id="phone" type="text" v-model="form.phone"></b-form-input>
              </b-form-group>
              <b-form-group label="Message" label-for="message">
                <b-form-textarea id="message" v-model="form.message" :rows="5" required></b-form-textarea>
              </b-form-group>
              <b-button type="submit" variant="primary" :class="{ pulse: sending }">{{ $t('contact.send') }}</b-button>
            </b-form>
            <b-alert variant="success" :show="success" class="mt-2 fade show">
              {{ $t('common.form.success') }}
            </b-alert>
            <b-alert variant="danger" :show="error" class="mt-2 fade show">
              {{ $t('common.form.error') }}
            </b-alert>
          </div>
        </b-col>
        <b-col md=6 class="mb-3 mb-md-0">
          <iframe class="bg-light mt-3 mt-md-0 w-100 h-100" style="min-height: 20rem;" src="https://maps.google.com/maps?q=La%20Ferme%20d'Oz%2C%20Oz%2C%20France&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import axios from "axios";

import jpg from "@/assets/images/background-contact.jpg";

export default {
  data() {
    return {
      form: {
        email: "",
        fullname: "",
        phone: "",
        message: "",
        secret_field: ""
      },
      sending: false,
      success: false,
      error: false
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.sending = true;
      this.success = false;
      this.error = false;
      axios
        .post("/api/contact-form", this.form)
        .then(() => {
          this.success = true;
          this.sending = false;
          this.resetForm();
        })
        .catch(() => {
          this.error = true;
          this.sending = false;
          this.resetForm();
        });
    },
    resetForm() {
      this.form.email = "";
      this.form.fullname = "";
      this.form.phone = "";
      this.form.message = "";
    }
  },
  mounted: function() {
    var cstyle = document.getElementById("app").style;
    cstyle.backgroundImage = 'url("' + jpg + '")';
    cstyle.backgroundSize = "cover";
    cstyle.backgroundPosition = "center";
    cstyle.backgroundAttachment = "fixed";
  }
};
</script>
