<template>
  <foz-detail :list="drink" :baseUrl="'drinks'" :nextSlug="next" :prevSlug="previous" v-if="drink" />
</template>

<script>
import { mapState, mapGetters } from "vuex";

import FozDetail from "@/components/FozDetail";

export default {
  components: { FozDetail },
  computed: {
    slug() {
      return this.route.params.slug;
    },
    drink() {
      return this.getDrinkBySlug(this.slug);
    },
    next() {
      return this.getNextDrinkSlugById(this.drink.id);
    },
    previous() {
      return this.getPreviousDrinkSlugById(this.drink.id);
    },
    ...mapState(["route"]),
    ...mapGetters([
      "getDrinkBySlug",
      "getNextDrinkSlugById",
      "getPreviousDrinkSlugById"
    ])
  }
};
</script>
