<template>
  <b-navbar id="head" toggleable="md" type="dark" variant="dark" fixed="top">
    <b-navbar-brand :to="{ name: 'home' }" class="d-md-none">
      <icon name="logo_h" height="auto" width="6rem"></icon>
    </b-navbar-brand>
    <b-nav-toggle target="nav_collapse"></b-nav-toggle>

    <b-collapse is-nav id="nav_collapse" class="mt-3 mt-md-0">
      <b-navbar-nav class="m-auto align-items-end">
        <b-nav-item class="mx-md-4" :to="{ name: 'menu-list'}">{{ $t('pages.menu-list.title') }}</b-nav-item>
        <b-nav-item class="mx-md-4" :to="{ name: 'drinks-list' }">{{ $t('pages.drinks-list.title') }}</b-nav-item>
        <b-nav-item :to="{ name: 'home' }" class="d-none d-md-inline">
          <icon name="logo_h" height="2rem" width="auto"></icon>
        </b-nav-item>
        <b-nav-item class="mx-md-4" :to="{ name: 'photos' }">{{ $t('pages.photos.title') }}</b-nav-item>
        <b-nav-item class="mx-md-4" :to="{ name: 'contact' }">{{ $t('pages.contact.title') }}</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav id="lang" class="small flex-row-reverse flex-md-column">
        <b-nav-item :to="{ name: '', params: { lang: 'en', transitionName: null }}" class="ml-3 ml-md-0">En</b-nav-item>
        <b-nav-item :to="{ name: '', params: { lang: 'fr', transitionName: null }}">Fr</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import "../icons/logo";
import "../icons/logo_h";

export default {};
</script>

<style lang="postcss" scoped>
#lang .nav-link {
  padding-top: 0.2rem;
  padding-bottom: 0;
}
</style>
