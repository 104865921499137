
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/weather-snowy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 14a1 1 0 1 1 0 2 5 5 0 0 1-.002-10 6.502 6.502 0 0 1 12.485 2.033L19 8a4 4 0 0 1 0 8h-1a1 1 0 1 1 0-2h1a2 2 0 1 0 0-4h-2V9a5 5 0 0 0-9.935-.806A3 3 0 1 0 6 14zm1.877 4.07l2.191-.588-1.604-1.603a1 1 0 1 1 1.415-1.415l1.603 1.604.587-2.19a1 1 0 0 1 1.932.517l-.587 2.19 2.19-.586a1 1 0 1 1 .518 1.932l-2.19.587 1.604 1.603a1 1 0 1 1-1.415 1.415l-1.603-1.604-.587 2.19a1 1 0 1 1-1.932-.517l.587-2.19-2.19.586a1 1 0 0 1-.519-1.932z"/>'
  }
})
