<template>
  <section class="foz-flex1 foz-full">
    <b-carousel id="carousel1" controls indicators :interval="4000" class="h-100">
      <b-carousel-slide v-for="image in images" :key="image" class="h-100">
        <img slot="img" class="d-block h-100 w-100 foz-cover" :src="image">
      </b-carousel-slide>
    </b-carousel>
  </section>
</template>

<script>
import photo1 from "@/assets/images/photo-1.jpg";
import photo2 from "@/assets/images/photo-2.jpg";
import photo3 from "@/assets/images/photo-3.jpg";
import photo4 from "@/assets/images/photo-4.jpg";
import photo5 from "@/assets/images/photo-5.jpg";
import photo6 from "@/assets/images/photo-6.jpg";
import photo7 from "@/assets/images/photo-7.jpg";

export default {
  data() {
    return {
      images: [photo1, photo2, photo3, photo4, photo5, photo6, photo7]
    };
  },
  mounted: function() {
    var cstyle = document.getElementById("app").style;
    cstyle.backgroundImage = "none";
  }
};
</script>

<style lang="postcss">
.foz-full {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#carousel1 .carousel-inner {
  height: 100%;
}
.foz-cover {
  object-fit: cover;
}
</style>
