
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menus/menus': {
    width: 16,
    height: 16,
    viewBox: '0 0 141.7 141.7',
    data: '<g _fill="#2f2f2f"><path pid="0" d="M57.3 85.6c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0-1.2 1-2.1 2.1-2.1s2.1 1 2.1 2.1zm0 0M57.3 94.2c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0-1.2 1-2.1 2.1-2.1 1.1-.1 2.1.9 2.1 2.1zm0 0M87.1 85.6c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1zm0 0M87.1 94.2c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0-1.2 1-2.1 2.1-2.1 1.2-.1 2.1.9 2.1 2.1zm0 0"/><path pid="1" d="M63.7 11.1c-1.2 0-2.1 1-2.1 2.1v27.7c0 2.8-1.8 5.1-4.3 6V13.2c0-1.2-1-2.1-2.1-2.1-1.2 0-2.1 1-2.1 2.1v33.7c-2.5-.9-4.3-3.2-4.3-6V13.2c0-1.2-1-2.1-2.1-2.1-1.2 0-2.1 1-2.1 2.1v27.7c0 5.1 3.7 9.4 8.5 10.4v21.8c-3.7 1-6.4 4.3-6.4 8.2v46.9c0 4.7 3.8 8.5 8.5 8.5s8.5-3.8 8.5-8.5V81.4c0-4-2.7-7.3-6.4-8.2V51.3c4.9-1 8.5-5.3 8.5-10.4V13.2c0-1.2-1-2.1-2.1-2.1zm-4.3 70.3v46.9c0 2.3-1.9 4.3-4.3 4.3s-4.3-1.9-4.3-4.3V81.4c0-2.3 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3zm0 0M91.2 14.7c-2.4-2.4-5.8-3.6-10.5-3.6-1.2 0-2.1 1-2.1 2.1V75c0 .2.1.4.1.7-1.4 1.5-2.3 3.5-2.3 5.7v46.9c0 4.7 3.8 8.5 8.5 8.5s8.5-3.8 8.5-8.5V81.4c0-2.7-1.3-5.1-3.3-6.7 5.4-7 5.4-17.4 5.4-32.6v-4.7c.2-10.2.3-18.2-4.3-22.7zm-1.9 113.5c0 2.3-1.9 4.3-4.3 4.3s-4.3-1.9-4.3-4.3V81.4c0-2.3 1.9-4.3 4.3-4.3 2.3 0 4.3 1.9 4.3 4.3v46.8zm2.1-90.8v4.7c0 15 0 25-5.3 30.9-.4 0-.7-.1-1.1-.1-.7 0-1.4.1-2.1.3V15.5c2.4.3 4.1 1 5.4 2.2 3.2 3.3 3.1 10.5 3.1 19.7zm0 0"/></g>'
  }
})
