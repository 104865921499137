
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/weather-lightning': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 16a5 5 0 0 1-.002-10 6.502 6.502 0 0 1 12.485 2.033L19 8a4 4 0 0 1 0 8h-1a1 1 0 1 1 0-2h1a2 2 0 1 0 0-4h-2V9a5 5 0 0 0-9.935-.806A3 3 0 1 0 6 14h1a1 1 0 1 1 0 2H6zm6-5h3l-2 4h2l-3.75 7 .75-5H9.5l2.5-6z"/>'
  }
})
