<template>
  <div id="webcam" class="bg-primary rounded-left p-1 my-2">
    <b-link target="_blank" href="http://www.oz-en-oisans.com/en-direct/webcam" class="d-block m-1">
      <icon name="md/webcam" class="text-white" width="1.75rem" height="auto"></icon>
    </b-link>
  </div>
</template>

<script>
import "@/icons/md/webcam";

export default {};
</script>
