<template>
  <section class="foz-flex1">
    <b-container>
      <h2 class="text-primary" style="position: absolute; bottom: 5rem;">Oups,<br/>
        <small>page not found!</small>
      </h2>
    </b-container>
  </section>
</template>

<script>
import jpg from "@/assets/images/notfound.jpg";

export default {
  mounted: function() {
    var cstyle = document.getElementById("app").style;
    cstyle.backgroundImage = 'url("' + jpg + '")';
    cstyle.backgroundSize = "cover";
    cstyle.backgroundPosition = "center";
    cstyle.backgroundAttachment = "fixed";
  }
};
</script>
