<template>
  <foz-detail :list="menu" :baseUrl="'menu'" :nextSlug="next" :prevSlug="previous" v-if="menu" />
</template>

<script>
import { mapState, mapGetters } from "vuex";

import FozDetail from "@/components/FozDetail";

export default {
  components: { FozDetail },
  computed: {
    slug() {
      return this.route.params.slug;
    },
    menu() {
      return this.getMenuBySlug(this.slug);
    },
    next() {
      return this.getNextMenuSlugById(this.menu.id);
    },
    previous() {
      return this.getPreviousMenuSlugById(this.menu.id);
    },
    ...mapState(["route"]),
    ...mapGetters([
      "getMenuBySlug",
      "getNextMenuSlugById",
      "getPreviousMenuSlugById"
    ])
  }
};
</script>
