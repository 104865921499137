
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drinks/hot-drinks': {
    width: 16,
    height: 16,
    viewBox: '0 0 141.7 141.7',
    data: '<g _fill="#2f2f2f"><path pid="0" d="M105.1 59.7c-1.8 0-3.4.3-5.1.8-.2-3.2-2.8-5.8-6-5.8H36.7c-3.3 0-6.1 2.8-6.1 6.1v32.7c0 5.9 3.9 10.8 9.3 12.5v5c0 .8.7 1.5 1.5 1.5h47.9c.8 0 1.5-.7 1.5-1.5v-5c5.1-1.6 8.8-6.2 9.2-11.6 1.7.5 3.3.8 5.1.8 9.8 0 17.7-7.9 17.7-17.7s-7.9-17.8-17.7-17.8zm-17.3 49.8h-45v-2.9H87.7v2.9zm9.2-16c0 5.6-4.5 10.1-10.1 10.1H43.8c-5.6 0-10.1-4.5-10.1-10.1V60.8c0-1.7 1.3-3 3-3H94c1.7 0 3 1.3 3 3v32.7zm3-23.4c1.5-1 3.3-1.6 5.1-1.6 4.9 0 8.9 4 8.9 8.9s-4 8.9-8.9 8.9c-1.8 0-3.6-.6-5.1-1.6V70.1zm5.1 22.1c-1.8 0-3.4-.3-5.1-.9v-3c1.6.8 3.3 1.2 5.1 1.2 6.6 0 11.9-5.3 11.9-11.9 0-6.6-5.3-11.9-11.9-11.9-1.8 0-3.5.4-5.1 1.2v-3c1.6-.6 3.3-.9 5.1-.9 8.1 0 14.7 6.6 14.7 14.7 0 7.9-6.6 14.5-14.7 14.5zM80.3 44.6c-1.3 1-2.2 2.5-2.2 4s.8 3 2.2 4c.3.2.6.3.9.3.5 0 .9-.3 1.3-.6.5-.7.3-1.6-.3-2.1-.6-.4-.9-1-.9-1.6 0-.6.3-1.1.9-1.6 1.3-1 2.2-2.5 2.2-4s-.8-3-2.2-4c-.6-.4-.9-1-.9-1.6s.3-1.1.9-1.6c.7-.5.8-1.4.3-2.1-.5-.7-1.4-.8-2.1-.3-1.3 1-2.2 2.5-2.2 4s.8 3 2.2 4c.6.4.9 1 .9 1.6 0 .6-.4 1.2-1 1.6zM65.7 44.6c-1.3 1-2.2 2.5-2.2 4s.8 3 2.2 4c.3.2.6.3.9.3.5 0 .9-.3 1.3-.6.5-.7.3-1.6-.3-2.1-.6-.4-.9-1-.9-1.6 0-.6.3-1.1.9-1.6 1.3-1 2.2-2.5 2.2-4s-.8-3-2.2-4c-.6-.4-.9-1-.9-1.6s.3-1.1.9-1.6c.7-.5.8-1.4.3-2.1-.5-.7-1.4-.8-2.1-.3-1.3 1-2.2 2.4-2.2 4 0 1.5.8 3 2.2 4 .6.4.9 1 .9 1.6-.1.6-.4 1.2-1 1.6zM51.1 44.6c-1.3 1-2.2 2.5-2.2 4s.8 3 2.2 4c.3.2.6.3.9.3.5 0 .9-.3 1.3-.6.5-.7.3-1.6-.3-2.1-.6-.4-.9-1-.9-1.6 0-.6.3-1.1.9-1.6 1.3-1 2.2-2.4 2.2-4 0-1.5-.8-3-2.2-4-.6-.4-.9-1-.9-1.6s.3-1.1.9-1.6c.7-.5.8-1.4.3-2.1-.5-.7-1.4-.8-2.1-.3-1.3 1-2.2 2.4-2.2 4 0 1.5.8 3 2.2 4 .6.4.9 1 .9 1.6-.1.6-.4 1.2-1 1.6z"/></g>'
  }
})
