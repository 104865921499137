
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/weather-cloudy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 19a5 5 0 0 1-.002-10 6.502 6.502 0 0 1 12.485 2.033L19 11a4 4 0 0 1 0 8H6zm13-6h-2v-1a5 5 0 0 0-9.935-.806A3 3 0 1 0 6 17h13a2 2 0 1 0 0-4z"/>'
  }
})
