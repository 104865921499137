
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/weather-rainy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 14a1 1 0 1 1 0 2 5 5 0 0 1-.002-10 6.502 6.502 0 0 1 12.485 2.033L19 8a4 4 0 0 1 0 8h-1a1 1 0 1 1 0-2h1a2 2 0 1 0 0-4h-2V9a5 5 0 0 0-9.935-.806A3 3 0 1 0 6 14zm8.829 1.671c1.561 1.562 1.561 3.846 0 5.407-.781.782-1.805.922-2.83.922-1.023 0-2.047-.14-2.827-.922-1.563-1.561-1.563-3.844 0-5.407L12 11l2.828 4.671zm-1.415 1.021L12 14.25l-1.414 2.442c-.781.817-.781 2.01 0 2.826.39.409.902.482 1.414.482.512 0 1.024-.073 1.414-.482.781-.816.781-2.01 0-2.826z"/>'
  }
})
