export const getMenuBySlug = state => slug => {
  return state.menus.find(menu => menu.slug === slug);
};
export const getNextMenuSlugById = state => id => {
  var mid = (id + 1) % state.menus.length;
  var menu = state.menus.find(menu => menu.id === mid);
  return menu.slug;
};
export const getPreviousMenuSlugById = state => id => {
  var mid = (state.menus.length + id - 1) % state.menus.length;
  var menu = state.menus.find(menu => menu.id === mid);
  return menu.slug;
};
export const getDrinkBySlug = state => slug => {
  return state.drinks.find(drinks => drinks.slug === slug);
};
export const getNextDrinkSlugById = state => id => {
  var mid = (id + 1) % state.drinks.length;
  var drink = state.drinks.find(drinks => drinks.id === mid);
  return drink.slug;
};
export const getPreviousDrinkSlugById = state => id => {
  var mid = (state.drinks.length + id - 1) % state.drinks.length;
  var drink = state.drinks.find(drinks => drinks.id === mid);
  return drink.slug;
};
