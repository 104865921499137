<template>
  <div class="bg-primary rounded-left p-1 my-2">
    <b-link v-b-modal.weather class="d-block m-1">
      <icon v-bind:name="icon" class="text-white" width="1.75rem" height="auto" />
    </b-link>
    <b-modal ref="weather" id="weather" hide-footer centered size="sm" class="text-gray" title="Oz-en-Oisans">
      <table>
        <tr>
          <td rowspan="2">
            <icon v-bind:name="icon" width="10rem" height="auto" />
          </td>
          <td class="lead">
            <icon name="md/thermometer" width="2rem" height="auto" />&nbsp;{{ temp }}&nbsp;°C</td>
        </tr>
        <tr>
          <td>
            <icon name="md/weather-windy" width="2rem" height="auto" />&nbsp;{{ wind }}&nbsp;km/h
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

import "@/icons/md/weather-sunny";
import "@/icons/md/weather-partlycloudy";
import "@/icons/md/weather-cloudy";
import "@/icons/md/weather-pouring";
import "@/icons/md/weather-rainy";
import "@/icons/md/weather-lightning";
import "@/icons/md/weather-snowy";
import "@/icons/md/weather-fog";
import "@/icons/md/weather-windy";
import "@/icons/md/thermometer";
import "@/icons/md/minus";

export default {
  data() {
    return {
      icon: "md/minus",
      temp: "-",
      wind: "-"
    };
  },
  created() {
    axios
      .get(
        "https://api.openweathermap.org/data/2.5/weather?lat=45.12870&lon=6.07260&APPID=0cae125fd3af08b4738abce3131ff010&units=metric"
      )
      .then(response => {
        var data = response.data;
        this.temp = Math.round(data.main.temp);
        this.wind = Math.round(data.wind.speed * 3.6);
        switch (data.weather[0].icon) {
          case "01d":
          case "01n":
            this.icon = "md/weather-sunny";
            break;
          case "02d":
          case "02n":
            this.icon = "md/weather-partlycloudy";
            break;
          case "03d":
          case "03n":
            this.icon = "md/weather-cloudy";
            break;
          case "04d":
          case "04n":
            this.icon = "md/weather-cloudy";
            break;
          case "09d":
          case "09n":
            this.icon = "md/weather-pouring";
            break;
          case "10d":
          case "10n":
            this.icon = "md/weather-rainy";
            break;
          case "11d":
          case "11n":
            this.icon = "md/weather-lightning";
            break;
          case "13d":
          case "13n":
            this.icon = "md/weather-snowy";
            break;
          case "50d":
          case "50n":
            this.icon = "md/weather-fog";
            break;
          default:
            this.icon = "md/minus";
            break;
        }
      })
      .catch(() => {
        this.icon = "md/minus";
      });
  }
};
</script>
