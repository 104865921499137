/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tripadvisor': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="#303c42"><circle pid="0" cx="6" cy="12.98" r="1"/><path pid="1" d="M6 10a3 3 0 1 0 3 3 3 3 0 0 0-3-3zm0 5a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/><circle pid="2" cx="18" cy="12.98" r="1"/><path pid="3" d="M18 10a3 3 0 1 0 3 3 3 3 0 0 0-3-3zm0 5a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/><path pid="4" d="M23 9.59a5.07 5.07 0 0 1 .93-1.77.5.5 0 0 0-.43-.82h-3.76A16 16 0 0 0 4.26 7H.5a.5.5 0 0 0-.38.82A5 5 0 0 1 1 9.59 6 6 0 0 0 10.48 17l1.09 1.79a.5.5 0 0 0 .85 0l1.1-1.79A6 6 0 0 0 23 9.59zM6 18a5 5 0 0 1-1.16-9.88l.31-.06A5 5 0 0 1 6 8a5 5 0 0 1 0 10zm12 0a5 5 0 0 1-4.14-2.21c-.09-.13-.15-.27-.22-.41a7.32 7.32 0 0 1-.64-2.54A5 5 0 0 1 18 8a5 5 0 0 1 .86.09l.3.06A5 5 0 0 1 18 18z"/></g>'
  }
})
