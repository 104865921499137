<template>
  <footer id="foot" class="d-flex flex-wrap flex-md-nowrap bg-white justify-content-center justify-content-md-between mt-3" v-if="route.name !== 'photos'">
    <div class="text-center">
      <b-button variant="link" href="tel:+33476110549" class="text-gray py-1 py-lg-2">
        <icon name="md/phone" class="text-primary"></icon>
        +33 4 76 11 05 49
      </b-button>
      <b-button variant="link" href="mailto:contact@lafermedoz.fr" class="text-gray py-1 py-lg-2">
        <icon name="md/email" class="text-primary"></icon>
        contact@lafermedoz.fr
      </b-button>
      <b-button variant="link" href="geo:45.12870,6.07260" class="text-gray py-1 py-lg-2 d-md-none">
        <icon name="md/map-marker" class="text-primary"></icon>
        Résidence le Chalet des neiges
        <span class="d-none d-sm-inline"> – </span><br class="d-sm-none" /> 38&nbsp;114 Oz-en-Oisans
      </b-button>
      <b-button variant="link" :to="{ name: 'contact' }" class="text-gray py-1 py-lg-2 d-none d-md-inline-block">
        <icon name="md/map-marker" class="text-primary"></icon>
        Résidence le Chalet des neiges
        <span class="d-none d-sm-inline"> – </span><br class="d-sm-none" /> 38&nbsp;114 Oz-en-Oisans
      </b-button>
    </div>
    <foz-credit class="my-auto"></foz-credit>
  </footer>
</template>

<script>
import { mapState } from "vuex";

import FozCredit from "@/components/FozCredit";
import "../icons/md/phone";
import "../icons/md/email";
import "../icons/md/map-marker";
import "../icons/md/copyright";

export default {
  components: { FozCredit },
  computed: {
    ...mapState(["route"])
  }
};
</script>
