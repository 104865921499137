
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/facebook-box': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm13 1.998h-2.5a3.5 3.5 0 0 0-3.5 3.5v2.5h-2v3h2v7h3v-7h3v-3h-3v-2a1 1 0 0 1 1-1h2v-3z"/>'
  }
})
