
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drinks/aperitif-liqueur': {
    width: 16,
    height: 16,
    viewBox: '0 0 141.7 141.7',
    data: '<path pid="0" d="M106.1 43.5c-.3-.7-1-1.1-1.7-1.1h-45l-3.6-7.6c1.3-1.2 2.2-2.9 2.2-4.9 0-3.6-2.9-6.5-6.5-6.5S45 26.3 45 29.9s2.9 6.5 6.5 6.5c.3 0 .7 0 1-.1l2.8 6H37.2c-.8 0-1.4.5-1.7 1.1-.3.7-.1 1.5.4 2l33 33v36H53.5c-1 0-1.9.8-1.9 1.9 0 1 .8 1.9 1.9 1.9h34.7c1 0 1.9-.8 1.9-1.9 0-1-.8-1.9-1.9-1.9H72.7v-36L90 61.3l15.7-15.7c.5-.6.7-1.4.4-2.1zM51.5 32.8c-1.5 0-2.8-1.3-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8 1.5 0 2.8 1.3 2.8 2.8 0 1.5-1.3 2.8-2.8 2.8zm-9.8 13.3h15.4l5.7 12h-9.1l-12-12zm29.1 29.1L57.4 61.8h26.8L70.8 75.2zM87.9 58H66.8l-5.7-12h38.7L87.9 58zm0 0" _fill="#2f2f2f"/><path pid="1" d="M84.3 48.6H73.8c-1 0-1.9.8-1.9 1.9 0 1 .8 1.9 1.9 1.9h10.4c1 0 1.9-.8 1.9-1.9 0-1.1-.8-1.9-1.8-1.9zm0 0M91.6 50.2c0 1-.8 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9 1 0 1.9.9 1.9 1.9zm0 0" _fill="#2f2f2f"/>'
  }
})
