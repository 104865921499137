<template>
  <div id="social" class="bg-primary rounded-left p-1 my-2">
    <b-link target="blank" href="https://www.instagram.com/lafermedoz/" class="d-block m-1">
      <icon name="md/instagram" class="text-white" width="1.75rem" height="auto"></icon>
    </b-link>
    <b-link target="blank" href="https://www.facebook.com/fermedoz/" class="d-block m-1">
      <icon name="md/facebook-box" class="text-white" width="1.75rem" height="auto"></icon>
    </b-link>
    <b-link target="blank" href="https://www.tripadvisor.fr/Restaurant_Review-g1368194-d3937890-Reviews-La_Ferme_d_Oz-Oz_en_Oisans_Isere_Auvergne_Rhone_Alpes.html" class="d-block m-1">
      <icon name="tripadvisor" class="text-white" width="1.75rem" height="auto"></icon>
    </b-link>
  </div>
</template>

<script>
import "@/icons/md/instagram";
import "@/icons/md/facebook-box";
import "@/icons/tripadvisor";

export default {};
</script>
