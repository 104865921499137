<template>
  <section
    class="
      d-flex
      flex-column
      justify-content-center
      foz-flex1
      position-relative
      pb-5
    "
  >
    <b-container class="text-center">
      <icon name="logo" height="15rem" width="auto" class="shadow"></icon>
      <h1 v-if="motto === 0" class="py-4 text-shadow">
        {{ $t("home.motto0.l1") }}<br />{{ $t("home.motto0.l2") }}
      </h1>
      <h1 v-if="motto === 1" class="py-4 text-shadow">
        {{ $t("home.motto1.l1") }}<br />{{ $t("home.motto1.l2") }}
      </h1>
      <h1 v-if="motto === 2" class="py-4 text-shadow">
        {{ $t("home.motto2.l1") }}<br />{{ $t("home.motto2.l2") }}
      </h1>
    </b-container>
    <footer
      id="links"
      class="
        position-absolute
        d-flex
        flex-column flex-sm-row
        justify-content-center
        bg-primary
        mt-5
      "
    >
      <b-button
        target="blank"
        variant="link"
        href="http://www.oz-en-oisans.com/"
        class="p-1 p-sm-2 btn-white"
      >
        Office du tourisme d’Oz-en-Oisans
      </b-button>
      <span class="d-none d-sm-inline py-2">&nbsp;|&nbsp;</span>
      <b-button
        target="blank"
        variant="link"
        href="http://www.chaletdesneiges.com/fr/station-alpe-d-huez/"
        class="p-1 p-sm-2 btn-white"
      >
        Résidence chalet des neiges
      </b-button>
    </footer>
    <foz-new-year-menu></foz-new-year-menu>
  </section>
</template>

<script>
import FozNewYearMenu from "@/components/FozNewYearMenu";
import "../icons/logo";
import jpg0 from "@/assets/images/carousel0.jpg";
import jpg1 from "@/assets/images/carousel1.jpg";
import jpg2 from "@/assets/images/carousel2.jpg";

let img = [jpg0, jpg1, jpg2];

export default {
  components: { FozNewYearMenu },
  data() {
    return {
      motto: Math.floor(Math.random() * 3),
    };
  },
  mounted: function () {
    var cstyle = document.getElementById("app").style;
    cstyle.backgroundSize = "cover";
    cstyle.backgroundPosition = "center";
    cstyle.backgroundAttachment = "fixed";
    cstyle.backgroundImage = 'url("' + img[this.motto] + '")';
  },
};
</script>

<style lang="postcss" scoped>
#links {
  bottom: -1rem;
  left: 0;
  right: 0;
}
svg.shadow {
  filter: drop-shadow(0px 0px 5px #000);
}
h1.text-shadow {
  text-shadow: 0 0 5px #000;
}
</style>
