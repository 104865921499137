<template>
  <div>
    <b-button v-b-modal.credit variant="link" class="text-gray py-1">
      <small>
        <icon name="md/copyright" width=".75rem" class="text-primary" /> Crédits
      </small>
    </b-button>
    <b-modal id="credit" hide-footer size="md" centered class="text-gray" title="Crédits">
      <dl>
        <dt>Webdesign&nbsp;:</dt>
        <dd>
          <a href="https://www.ma-little-agence.com">
            <icon name="md/copyright" width=".75rem" class="text-primary" />&nbsp;Ma little agence
          </a>
        </dd>
        <dt>Développement&nbsp;:</dt>
        <dd>
          <icon name="md/copyright" width=".75rem" class="text-primary" />&nbsp;Rodolphe Pelloux-Prayer
        </dd>
        <dt>Photos&nbsp;:</dt>
        <dd>
          Page «&nbsp;Nous contacter&nbsp;»&nbsp;:
          <icon name="md/copyright" width=".75rem" class="text-primary" />&nbsp;Images et rêves
        </dd>
        <dd>
          Pages«&nbsp;Cartes et menu&nbsp;» et «&nbsp;Boissons&nbsp;»&nbsp;:
          <icon name="md/copyright" width=".75rem" class="text-primary" />&nbsp;Pixabay
        </dd>
        <dd>
          Page «&nbsp;Photos&nbsp;»&nbsp;:
          <icon name="md/copyright" width=".75rem" class="text-primary" />&nbsp;Franck Ripoteau, Franck Ménard
        </dd>
      </dl>
    </b-modal>
  </div>
</template>

<script>
import "@/icons/md/copyright";

export default {};
</script>
