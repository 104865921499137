
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'md/webcam': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a7 7 0 1 1 0 14 7 7 0 0 1 0-14zm0 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6zM6 22a2 2 0 0 1-1.714-3.03l1.826-3.163A8.965 8.965 0 0 0 12 18c2.251 0 4.31-.826 5.887-2.193l1.827 3.162A2 2 0 0 1 18 22l-12 .001z"/>'
  }
})
