<template>
  <b-row>
    <b-col :id="item.slug" v-for="item in drinks" v-bind:key="item.slug" v-bind:lg="3 * item.size" v-bind:md="6 * item.size" align="center" class="box">
      <b-link :to="{ name: 'drinks-detail', params: { slug: item.slug } }" class="no-decoration d-flex flex-column h-100">
        <div class="px-2 py-4" style="flex: 1;" v-bind:class="[item.inverted ? 'bg-primary' : 'bg-white']">
          <icon v-bind:name="item.logo" width="8rem" height="auto" class="text-dark"></icon>
          <h4 class="mb-0 pt-3" v-bind:class="[item.inverted ? 'text-white' : 'text-primary']">{{ $t(item.title) }}</h4>
        </div>
        <div class="p-2" v-bind:class="[item.inverted ? 'bg-white text-primary' : 'bg-primary text-white']">> {{ $t('common.more') }}</div>
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";

import "@/icons/drinks/hot-drinks";
import "@/icons/drinks/aperitif-liqueur";
import "@/icons/drinks/beers";
import "@/icons/drinks/wines";

export default {
  computed: mapState(["drinks"])
};
</script>

<style lang="postcss" scoped>
.no-decoration:hover {
  text-decoration: none;
}
.box {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
